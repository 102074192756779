let url;

if (process.env.REACT_APP_ENV === 'LOCAL') {
	url = 'http://localhost:8071/dev-api';
} else if (process.env.REACT_APP_ENV === 'DEV') {
	url = 'https://dev.dienapodienos.lt/dev-api';
} else if (process.env.REACT_APP_ENV === 'PROD') {
	url = 'https://dienapodienos.lt/api';
} else {
	url = 'http://true-ip/api';
}

const ServerParameters = {
	API_URL: url
};

export default ServerParameters;