import { useEffect, useState } from 'react';
import axios from 'axios'
import ServerParameters from "./ServerParameters";
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";

export default function UsersPage() {

    const [users, setUsers] = useState([]);

    useEffect(() => {
        axios
            .get(ServerParameters.API_URL + "/user")
            .then(response => {
                setUsers(response.data);
            });
    }, []);

    return <>
        <div>
            <ul>
                {users.map(user =>
                    <ol key={user.id}>
                        <Link to={`/little-notes/user/${user.id}`} style={{ textDecoration: 'none' }}>
                            <span>{user.email} </span>
                        </Link>
                    </ol>
                )}
            </ul>
        </div>
    </>

}