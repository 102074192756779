import { useParams } from "react-router-dom";
import axios from 'axios'
import ServerParameters from "./ServerParameters";
import Moment from 'moment';
import { useEffect, useState } from 'react';

export default function LittleNotesDayPage() {
    const params = useParams();
    const [littleNotes, setLittleNotes] = useState([]);

    useEffect(() => {
        axios
            .get(ServerParameters.API_URL + `/little-notes/date`, {
                params: {
                    date: params.datetime
                }
            })
            .then(response => {
                setLittleNotes(response.data);
            });
    }, []);

    return <>
        {params.datetime}
        <div>
            <ul>
                {littleNotes.map(note =>
                    <ol key={note.id}>
                        <span>{Moment(note.createdAt).format('YYYY-MM-DD HH:mm:ss')} </span>
                        <span>({note.user.email}): </span>
                        <span>{note.note} </span>
                    </ol>
                )}
            </ul>
        </div>
    </>
}