import { useEffect, useState } from 'react';
import axios from 'axios'
import ServerParameters from "./ServerParameters";
import { useNavigate } from 'react-router-dom';
import { Link, useParams } from "react-router-dom";
import Modal from 'react-modal';
import Moment from 'moment';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

export default function LittleNotesPage() {

    const navigate = useNavigate();

    let subtitle;

    const [littleNotes, setLittleNotes] = useState([]);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [newLittleNote, setNewLittleNote] = useState("");
    const [editNoteId, setEditNoteId] = useState(null);
    const [refreshKey, setRefreshKey] = useState(0);
    const params = useParams();

    function openNewLittleNoteModal() {
        setNewLittleNote("");
        setEditNoteId(null);
        openModal();
    }

    function openEditLittleNoteModal(note) {
        setEditNoteId(note.id);
        setNewLittleNote(note.note);
        openModal();
    }

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    function handleLittleNoteChangeChange(e) {
        setNewLittleNote(e.target.value);
    }

    function deleteNote(id) {
        axios
            .delete(ServerParameters.API_URL + `/little-notes/${id}`)
            .then(response => {
                setRefreshKey(oldKey => oldKey + 1)
            });
    }

    async function addNewLittleNote(e) {
        e.preventDefault();

        console.log("submit");

        if (editNoteId) {
            axios
                .put(ServerParameters.API_URL + `/little-notes/${editNoteId}`, {
                    note: newLittleNote
                })
                .then(response => {
                    setRefreshKey(oldKey => oldKey + 1)
                });
        } else {
            axios
                .post(ServerParameters.API_URL + "/little-notes", {
                    note: newLittleNote
                })
                .then(response => {
                    setRefreshKey(oldKey => oldKey + 1)
                });
        }
    }

    useEffect(() => {
        if (params.userId) {
            axios
                .get(ServerParameters.API_URL + `/little-notes/user/${params.userId}`)
                .then(response => {
                    setLittleNotes(response.data);
                });
        } else {
            axios
                .get(ServerParameters.API_URL + "/little-notes")
                .then(response => {
                    setLittleNotes(response.data);
                });
        }

        setIsOpen(false);
        setNewLittleNote("");
        setEditNoteId(null);
    }, [refreshKey, navigate]);

    return (
        <>
            {!params.userId &&
                < div >
                    <div>
                        <button onClick={openEditLittleNoteModal}>+</button>
                    </div>
                    <Modal
                        isOpen={modalIsOpen}
                        onAfterOpen={afterOpenModal}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >
                        <button onClick={closeModal}>X</button>
                        <div>{newLittleNote}</div>

                        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Mažas įrašas</h2>
                        <form onSubmit={addNewLittleNote}>
                            <input value={newLittleNote} onChange={(e) => handleLittleNoteChangeChange(e)} />
                            <button>Pridėti</button>
                        </form>
                    </Modal>
                </div >
            }
            <div>
                <ul>
                    {littleNotes.map(note =>
                        <ol key={note.id}>
                            <span>{Moment(note.createdAt).format('YYYY-MM-DD HH:mm:ss')} </span>
                            <span onClick={() => openEditLittleNoteModal(note)}>{note.note} </span>
                            {!params.userId && <button onClick={() => deleteNote(note.id)}>X</button>}
                        </ol>
                    )}
                </ul>
            </div>
        </>
    );

}