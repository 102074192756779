import { useEffect, useState } from 'react';
import axios from 'axios'
import ServerParameters from "./ServerParameters";
import { useNavigate } from 'react-router-dom';
import { Link, useParams } from "react-router-dom";
import Modal from 'react-modal';
import Moment from 'moment';
import imageCompression from 'browser-image-compression';


export default function PhotosPage() {

    const navigate = useNavigate();

    const [photos, setPhotos] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [refreshKey, setRefreshKey] = useState(0);
    const params = useParams();

    function deletePhoto(id) {
        console.log(id);
        axios
            .delete(ServerParameters.API_URL + `/photos/${id}`)
            .then(response => {
                setRefreshKey(oldKey => oldKey + 1)
            });
    }

    function convertBase64(file) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    async function addPhoto() {
        const options = {
            maxSizeMB: 0.95,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        }

        const compressedFile = await imageCompression(selectedImage, options);
        console.log(compressedFile);

        convertBase64(compressedFile).then(res => {
            axios.post(ServerParameters.API_URL + "/photos", {
                fileName: selectedImage.name,
                contentBase64: res.split(",").slice(1)[0]
            }).then(response => {
                setSelectedImage(null);
                setRefreshKey(oldKey => oldKey + 1)
            });
        });
    }

    useEffect(() => {
        axios
            .get(ServerParameters.API_URL + "/photos")
            .then(response => {
                setPhotos(response.data);
            });

    }, [refreshKey, navigate]);

    return (
        <>
            <div>
                Vaizdai

                {/* Conditionally render the selected image if it exists */}
                {selectedImage && (
                    <div>
                        {/* Display the selected image */}
                        <img
                            alt="not found"
                            width={"250px"}
                            src={URL.createObjectURL(selectedImage)}
                        />
                        <br /> <br />
                        {/* Button to remove the selected image */}
                        <button onClick={() => setSelectedImage(null)}>Remove</button>
                        <button onClick={() => addPhoto()}> Save</button>
                    </div>
                )}

                <br />

                {/* Input element to select an image file */}
                <input
                    type="file"
                    name="myImage"
                    // Event handler to capture file selection and update the state
                    onChange={(event) => {
                        console.log(event.target.files[0]); // Log the selected file
                        setSelectedImage(event.target.files[0]); // Update the state with the selected file
                    }}
                />
            </div>
            <div>
                <ul>
                    {photos.map(photo =>
                        <ol key={photo.id}>
                            <div>{Moment(photo.createdAt).format('YYYY-MM-DD HH:mm:ss')} </div>
                            <img
                                src={photo.url}
                                alt="new"
                                max-width="500"
                            />
                            <button onClick={() => deletePhoto(photo.id)}>X</button>
                        </ol>
                    )}
                </ul>
            </div>
        </>
    );

}