import { useEffect, useState } from 'react';
import axios from 'axios'
import ServerParameters from "./ServerParameters";
import { useNavigate } from 'react-router-dom';
import { Link, useParams } from "react-router-dom";
import Modal from 'react-modal';
import Moment from 'moment';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

export default function SecretNotesPage() {

    const navigate = useNavigate();

    let subtitle;

    const [secretNotes, setSecretNotes] = useState([]);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [newSecretNote, setNewSecretNote] = useState("");
    const [editNoteId, setEditNoteId] = useState(null);
    const [refreshKey, setRefreshKey] = useState(0);
    const params = useParams();

    function openNewSecretNoteModal() {
        setNewSecretNote("");
        setEditNoteId(null);
        openModal();
    }

    function openEditSecretNoteModal(note) {
        setEditNoteId(note.id);
        setNewSecretNote(note.note);
        openModal();
    }

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    function handleSecretNoteChangeChange(e) {
        setNewSecretNote(e.target.value);
    }

    function deleteNote(id) {
        axios
            .delete(ServerParameters.API_URL + `/secret-notes/${id}`)
            .then(response => {
                setRefreshKey(oldKey => oldKey + 1)
            });
    }

    async function addNewSecretNote(e) {
        e.preventDefault();

        console.log("submit");

        if (editNoteId) {
            axios
                .put(ServerParameters.API_URL + `/secret-notes/${editNoteId}`, {
                    note: newSecretNote
                })
                .then(response => {
                    setRefreshKey(oldKey => oldKey + 1)
                });
        } else {
            axios
                .post(ServerParameters.API_URL + "/secret-notes", {
                    note: newSecretNote
                })
                .then(response => {
                    setRefreshKey(oldKey => oldKey + 1)
                });
        }
    }

    useEffect(() => {
        axios
            .get(ServerParameters.API_URL + "/secret-notes")
            .then(response => {
                setSecretNotes(response.data);
            });

        setIsOpen(false);
        setNewSecretNote("");
        setEditNoteId(null);
    }, [refreshKey]);

    return (
        <>
            {!params.userId &&
                < div >
                    <div>
                        <button onClick={openEditSecretNoteModal}>+</button>
                    </div>
                    <Modal
                        isOpen={modalIsOpen}
                        onAfterOpen={afterOpenModal}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >
                        <button onClick={closeModal}>X</button>
                        <div>{newSecretNote}</div>

                        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Slaptas įrašas</h2>
                        <form onSubmit={addNewSecretNote}>
                            <input value={newSecretNote} onChange={(e) => handleSecretNoteChangeChange(e)} />
                            <button>Pridėti</button>
                        </form>
                    </Modal>
                </div >
            }
            <div>
                <ul>
                    {secretNotes.map(note =>
                        <ol key={note.id}>
                            <span>{Moment(note.createdAt).format('YYYY-MM-DD HH:mm:ss')} </span>
                            <span onClick={() => openEditSecretNoteModal(note)}>{note.note} </span>
                            {!params.userId && <button onClick={() => deleteNote(note.id)}>X</button>}
                        </ol>
                    )}
                </ul>
            </div>
        </>
    );

}