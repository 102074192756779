import { useEffect, useState, useRef } from 'react';
import axios from 'axios'
import ServerParameters from "./ServerParameters";
import Moment, { duration, min } from 'moment';
import Modal from 'react-modal';
import Select from 'react-select';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const workoutOptions = [
    { value: 'PULL_UPS', label: 'Prisitraukimai' },
    { value: 'PUSH_UPS', label: 'Atsispaudimai' },
    { value: 'RUNNING', label: 'Bėgimas' },
];

const pullUpsOptions = [
    { value: 'NORMAL', label: 'Paprasti' },
    { value: 'RESISTANCE_BAND', label: 'Su guma' },
    { value: 'CHIN_UPS', label: 'Delnai į save' },
];

const pushUpsOptions = [
    { value: 'NORMAL', label: 'Paprasti' },
    { value: 'DIAMOND', label: 'Deimantiniai' },
    { value: 'DECLINE', label: 'Pakeltom kojom' },
    { value: 'SPIDERMAN', label: 'Žmogaus voro' },
    { value: 'KNEE_TO_ELBOW', label: 'Kelias prie alkūnės' },
    { value: 'SPHINX', label: 'Sfinkso' },
];

const transalations = {
    PULL_UPS: "Prisitraukimai",
    PUSH_UPS: "Atsispaudimai",
    RUNNING: "Bėgimas",
    NORMAL: "Paprasti",
    DIAMOND: "Deimantiniai",
    DECLINE: "Pakeltom kojom",
    SPIDERMAN: "Žmogaus voro",
    KNEE_TO_ELBOW: "Kelias prie alkūnės",
    SPHINX: "Sfinkso",
    RESISTANCE_BAND: "Su guma",
    CHIN_UPS: "Delnais į save",
}

export default function PhysicalActivitiesPage() {


    const [refreshKey, setRefreshKey] = useState(0);
    let subtitle;

    const [littleNotes, setLittleNotes] = useState([]);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [newLittleNote, setNewLittleNote] = useState("");
    const [editNoteId, setEditNoteId] = useState(null);
    const [workout, setWorkout] = useState(null);
    const [pullUps, setPullUps] = useState([]);
    const [pushUps, setPushUps] = useState([]);
    const [running, setRunning] = useState(null);
    const [runningTimeValue, setRunningTimeValue] = useState("0:00");
    const [physicalActivities, setPhysicalActivities] = useState([]);

    function openNewLittleNoteModal() {
        setNewLittleNote("");
        setEditNoteId(null);
        openModal();
    }

    function openEditLittleNoteModal(note) {
        setEditNoteId(note.id);
        setNewLittleNote(note.note);
        openModal();
    }

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    function handleWorkoutChange(selectedOption) {
        setWorkout(selectedOption.value);
        if (selectedOption.value === "PULL_UPS") {
            setPullUps([{
                type: "NORMAL",
                reps: null
            }]);
        } else if (selectedOption.value === "PUSH_UPS") {
            setPushUps([{
                type: "NORMAL",
                reps: null
            }]);
        } else if (selectedOption.value === "RUNNING") {
            setRunning({
                duration: null,
                distanceKm: null
            });
            setRunningTimeValue("0:00");
        }
    };

    function addPullUpsActivity(e) {
        e.preventDefault();

        if (pullUps.length < 1) {
            return;
        }
        if (pullUps.some(entry => entry.type == null || entry.reps == null || entry.reps < 1)) {
            return;
        }

        axios
            .post(ServerParameters.API_URL + "/physical-activities/pull-ups", {
                sets: pullUps
            })
            .then(response => {
                setRefreshKey(oldKey => oldKey + 1)
            });
    }


    function handlePullUpRepsChange(event, index) {
        setPullUps(
            pullUps.map((e, i) => {
                if (i == index) {
                    return {
                        ...pullUps[index],
                        reps: event.target.value,
                    }
                }
                return e;
            })
        );
    }

    function handlePullUpTypeChange(option, index) {
        setPullUps(
            pullUps.map((e, i) => {
                if (i == index) {
                    return {
                        ...pullUps[index],
                        type: option.value,
                    }
                }
                return e;
            })
        );
    }

    function handleAddPullUpsSet() {
        setPullUps([
            ...pullUps,
            {
                type: pullUps[pullUps.length - 1].type,
                reps: null
            }
        ]);
    }

    function handlePullUpsDeleteSet(index) {
        setPullUps([...pullUps.slice(0, index), ...pullUps.slice(index + 1)]);
    }

    function addPushUpsActivity(e) {
        e.preventDefault();

        if (pushUps.length < 1) {
            return;
        }
        if (pushUps.some(entry => entry.type == null || entry.reps == null || entry.reps < 1)) {
            return;
        }

        axios
            .post(ServerParameters.API_URL + "/physical-activities/push-ups", {
                sets: pushUps
            })
            .then(response => {
                setRefreshKey(oldKey => oldKey + 1)
            });
    }


    function handlePushUpRepsChange(event, index) {
        setPushUps(
            pushUps.map((e, i) => {
                if (i == index) {
                    return {
                        ...pushUps[index],
                        reps: event.target.value,
                    }
                }
                return e;
            })
        );
    }

    function handlePushUpTypeChange(option, index) {
        setPushUps(
            pushUps.map((e, i) => {
                if (i == index) {
                    return {
                        ...pushUps[index],
                        type: option.value,
                    }
                }
                return e;
            })
        );
    }

    function handleAddPushUpsSet() {
        setPushUps([
            ...pushUps,
            {
                type: pushUps[pushUps.length - 1].type,
                reps: null
            }
        ]);
    }


    function handlePushUpsDeleteSet(index) {
        setPushUps([...pushUps.slice(0, index), ...pushUps.slice(index + 1)]);
    }

    function addRunningActivity(e) {
        e.preventDefault();

        if (running.distanceKm <= 0) {
            return;
        }

        if (running.duration <= 0) {
            return;
        }

        console.log(running);


        axios
            .post(ServerParameters.API_URL + "/physical-activities/running", running)
            .then(response => {
                setRefreshKey(oldKey => oldKey + 1)
            });
    }

    function deleteActivity(id) {
        axios
            .delete(ServerParameters.API_URL + `/physical-activities/${id}`)
            .then(response => {
                setRefreshKey(oldKey => oldKey + 1)
            });
    }

    const handleRunningTimeChange = (event) => {
        setRunningTimeValue(event.target.value);
        const seconds = Math.max(0, getSecondsFromHHMMSS(event.target.value));
        setRunning({
            ...running,
            duration: seconds
        });
    };

    function handleRunningDistanceChange(event) {
        setRunning({
            ...running,
            distanceKm: event.target.value
        });
    }

    const handleRunningTimeBlur = (event) => {
        const value = event.target.value;
        const seconds = Math.max(0, getSecondsFromHHMMSS(value));

        const time = toHHMMSS(seconds);
        setRunningTimeValue(time);
    };

    const getSecondsFromHHMMSS = (value) => {
        const [str1, str2, str3] = value.split(":");

        const val1 = Number(str1);
        const val2 = Number(str2);
        const val3 = Number(str3);

        if (!isNaN(val1) && isNaN(val2) && isNaN(val3)) {
            // seconds
            return val1;
        }

        if (!isNaN(val1) && !isNaN(val2) && isNaN(val3)) {
            // minutes * 60 + seconds
            return val1 * 60 + val2;
        }

        if (!isNaN(val1) && !isNaN(val2) && !isNaN(val3)) {
            // hours * 60 * 60 + minutes * 60 + seconds
            return val1 * 60 * 60 + val2 * 60 + val3;
        }

        return 0;
    };

    const toHHMMSS = (secs) => {
        const secNum = parseInt(secs.toString(), 10);
        const hours = Math.floor(secNum / 3600);
        const minutes = Math.floor(secNum / 60) % 60;
        const seconds = secNum % 60;

        return [hours, minutes, seconds]
            .map((val) => (val < 10 ? `0${val}` : val))
            .filter((val, index) => val !== "00" || index > 0)
            .join(":")
            .replace(/^0/, "");
    };

    function getPaceText(duration, km) {
        const paceSeconds = Math.floor(duration/km);
        const seconds = paceSeconds % 60;
        const minutes = Math.floor(paceSeconds / 60);
        
        return minutes + ":" + seconds + " min/km";
    }

    useEffect(() => {
        axios
            .get(ServerParameters.API_URL + "/physical-activities")
            .then(response => {
                setPhysicalActivities(response.data);
            });

        setIsOpen(false);
        setWorkout(null);
    }, [refreshKey]);

    return (
        <>
            < div >
                <div>
                    <button onClick={openEditLittleNoteModal}>+</button>
                </div>
                <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <button onClick={closeModal}>X</button>
                    <div>{newLittleNote}</div>

                    <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Fizinė veikla</h2>

                    <label for="physical-activity-type">Veiklos tipas:</label>

                    <Select
                        value={workoutOptions.filter(function (option) { return option.value === workout; })}
                        onChange={e => handleWorkoutChange(e)}
                        options={workoutOptions}
                    />

                    {workout === "PULL_UPS" &&
                        <form onSubmit={addPullUpsActivity}>
                            {pullUps.map((entry, index) =>
                                <div>
                                    <input value={pullUps[index].reps} onChange={(e) => handlePullUpRepsChange(e, index)} className='inline' type='number'></input>
                                    <Select className='inline'
                                        value={pullUpsOptions.filter(function (option) { return option.value === pullUps[index].type; })}
                                        onChange={e => handlePullUpTypeChange(e, index)}
                                        options={pullUpsOptions}
                                    />
                                    {index != 0 && <button type='button' onClick={() => handlePullUpsDeleteSet(index)}>X</button>}
                                </div>
                            )}
                            <button type='button' onClick={handleAddPullUpsSet}>+serija</button>
                            <button>Pridėti</button>
                        </form>
                    }
                    {workout === "PUSH_UPS" &&
                        <form onSubmit={addPushUpsActivity}>
                            {pushUps.map((entry, index) =>
                                <div>
                                    <input value={pushUps[index].reps} onChange={(e) => handlePushUpRepsChange(e, index)} className='inline' type='number'></input>
                                    <Select className='inline'
                                        value={pushUpsOptions.filter(function (option) { return option.value === pushUps[index].type; })}
                                        onChange={e => handlePushUpTypeChange(e, index)}
                                        options={pushUpsOptions}
                                    />
                                    {index != 0 && <button type='button' onClick={() => handlePushUpsDeleteSet(index)}>X</button>}
                                </div>
                            )}
                            <button type='button' onClick={handleAddPushUpsSet}>+serija</button>
                            <button>Pridėti</button>
                        </form>
                    }
                    {workout === "RUNNING" &&
                        <form onSubmit={addRunningActivity}>
                            <div>
                                <label>Atstumas (km)</label>
                                <input
                                    type='number'
                                    onChange={handleRunningDistanceChange}
                                    value={running.distanceKm}
                                    min="0.01"
                                    step="0.001"
                                />
                            </div>
                            <div>
                                <label>Laikas</label>
                                <input
                                    className='time-duration'
                                    type="text"
                                    onChange={handleRunningTimeChange}
                                    onBlur={handleRunningTimeBlur}
                                    value={runningTimeValue}
                                />
                            </div>
                            <button>Pridėti</button>
                        </form>
                    }
                </Modal>
                {physicalActivities.map(activity =>
                    <ol key={activity.id}>
                        <span>{Moment(activity.createdAt).format('YYYY-MM-DD HH:mm:ss')} </span>
                        <span>{transalations[activity.workout.type]}</span>
                        <span><button onClick={() => deleteActivity(activity.id)}>X</button></span>
                        {activity.workout.type != "RUNNING" && <>
                            <div>{activity.workout.sets.map((set, index) =>
                                <ol key={index}>
                                    {index + 1} serija: {set.reps} ({transalations[set.type]})
                                </ol>
                            )}</div>
                            <div>----------------------------------------------------</div>
                            <div>viso: {activity.workout.summary.total}
                                {Object.entries(activity.workout.summary.totalByTypes).map(([key, value]) => <span> ({value} {transalations[key]})</span>)}
                            </div>
                        </>
                        }
                        {activity.workout.type == "RUNNING" && <>
                            <div>Distancija {activity.workout.distanceKm}km</div>
                            <div>Laikas {toHHMMSS(activity.workout.duration)}</div>
                            <div>Greitis {getPaceText(activity.workout.duration, activity.workout.distanceKm)}</div>
                        </>
                        }
                        <br></br>
                    </ol>
                )}
            </div >
        </>
    );

}