import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState, useEffect, createContext } from 'react';
import { Navigate } from "react-router-dom";
import axios from "axios";

import RegistrationPage from "./RegistrationPage";
import LoginPage from "./LoginPage";
import Layout from "./Layout";
import UserContext from "./UserContex";
import ServerParameters from "./ServerParameters";
import WithAxios from "./WithAxios";
import LogoutPage from "./LogoutPage";
import UsersPage from "./UsersPage";
import PasswordResetPage from "./PasswordResetPage";
import VerifyEmailPage from "./VerifyEmailPage";
import LittleNotesPage from "./LittleNotesPage";
import { ToastContainer, toast } from 'react-toastify';
import ForgotPasswordPage from "./ForgotPasswordPage";
import SecretNotesPage from "./SecretNotesPage";
import CalendarPage from "./CalendarPage";
import LittleNotesDayPage from "./LittleNotesDayPage";
import PhotosPage from "./PhotosPage";
import PhysicalActivitiesPage from "./PhysicalActivitiesPage";

export default function Router() {

    const [user, setUser] = useState(null);

    useEffect(() => {
        axios
            .get(ServerParameters.API_URL + "/auth/account")
            .then((response) => {
                setUser(response.data);
            });
    }, []);

    return (
        <UserContext.Provider value={{ user: user, setUser: setUser }}>
            <ToastContainer />
            <WithAxios>
                <BrowserRouter>
                    <Routes>
                        {
                            user
                                ?
                                <Route path="/" element={<Layout />}>
                                    <Route path="little-notes" element={<LittleNotesPage />} />
                                    <Route path="little-notes/:datetime" element={<LittleNotesDayPage />} />
                                    <Route path="little-notes/user/:userId" element={<LittleNotesPage />} />
                                    <Route path="secret-notes" element={<SecretNotesPage />} />
                                    <Route path="calendar" element={<CalendarPage />} />
                                    <Route path="users" element={<UsersPage />} />
                                    <Route path="photos" element={<PhotosPage />} />
                                    <Route path="physical-activities" element={<PhysicalActivitiesPage />} />
                                    <Route path="logout" element={<LogoutPage />} />
                                    <Route path="*" element={<Navigate to="/little-notes" replace />} />
                                </Route>
                                :
                                <Route path="/" element={<Layout />}>
                                    <Route path="registration" element={<RegistrationPage />} />
                                    <Route path="login" element={<LoginPage />} />
                                    <Route path="verify-email/:tokenId" element={<VerifyEmailPage />} />
                                    <Route path="forgot-password" element={<ForgotPasswordPage />} />
                                    <Route path="reset-password/:tokenId" element={<PasswordResetPage />} />
                                    <Route path="/" element={<Navigate to="/login" replace />} />
                                    <Route path="*" element={<Navigate to="/login" replace />} />
                                </Route>
                        }

                    </Routes>
                </BrowserRouter>
            </WithAxios>
        </UserContext.Provider>
    );

}