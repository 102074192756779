import { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { useNavigate } from "react-router-dom";
import axios from 'axios'
import ServerParameters from "./ServerParameters";

const localizer = momentLocalizer(moment)

export default function CalendarPage() {

    const navigate = useNavigate();
    const [events, setEvents] = useState([]);
    const [from, setFrom] = useState(moment().subtract(40, 'days').format("YYYY-MM-DD"));
    const [to, setTo] = useState(moment().add(40, 'days').format("YYYY-MM-DD"));
    const [refreshKey, setRefreshKey] = useState(0);

    useEffect(() => {
        axios
            .get(ServerParameters.API_URL + `/calendar`, {
                params: { from, to }
            })
            .then(response => {
                console.log(response.data);
                setEvents(
                    response.data.map(e => {
                        const event = {};
                        event.start = new Date(e.date);
                        event.end = new Date(e.date);
                        event.title = e.users.join("|");

                        return event;
                    })
                );
            });
    }, [refreshKey]);

    function handleSelecetSlot(e) {
        navigate(`/little-notes/${e.start.toISOString().substring(0, 10)}`);
    }

    function handleRangeChange(e) {
        setFrom(moment(Date.parse(e.start)).subtract(1, "days").format("YYYY-MM-DD"));
        setTo(moment(Date.parse(e.end)).add(1, "days").format("YYYY-MM-DD"));
        setRefreshKey(oldKey => oldKey + 1);
    }

    return <>
        <div>
            <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500, width: 500 }}
                views={['month']}
                onSelectEvent={(e) => handleSelecetSlot(e)}
                onRangeChange={(e) => handleRangeChange(e)}
            />
        </div>
    </>
}
